<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th
            v-for="(item, index) in metadata"
            :key="index"
            class="primary--text font-weight-bold"
            v-text="item.keyAttribute"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Action'"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in dataApp"
          :key="index"
        >
          <td
            v-for="(keyValue, i) in dataKey"
            :key="i"
          >
            <div v-if="Array.isArray(item[keyValue])">
              <v-list-group
                v-for="(item, index) in item[keyValue]"
                :key="index"
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.id" />
                  </v-list-item-content>
                </template>

                <v-list-item>
                  <!-- <v-list-item-title v-text="item" /> -->
                  <p v-text="item" />
                </v-list-item>
              </v-list-group>
            </div>
            <v-list-group
              v-else-if="typeof item[keyValue] === 'object' && item[keyValue] !== null"
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item[keyValue].id" />
                </v-list-item-content>
              </template>

              <v-list-item>
                <!-- <v-list-item-title v-text="item[keyValue]" /> -->
                <p v-text="item[keyValue]" />
              </v-list-item>
            </v-list-group>
            <!-- <div
              v-else
              v-text="item[keyValue] || ''"
              :style="typeof item[keyValue] === 'string' && item[keyValue].length > 1000 ? 'width: 500px' : 'width: auto'"
            /> -->
            <div
              v-else-if="typeof item[keyValue] === 'string' && item[keyValue].length > 1000"
              style="width: 500px"
            >
              {{ isShowContent ? item[keyValue].substr(0, 170) : item[keyValue] || '' }}
              <a
                style="color: #0f0fe9"
                @click="isShowContent = !isShowContent"
                v-text="isShowContent ? 'Show more' : 'Show less'"
              />
            </div>
            <div
              v-else
              v-text="item[keyValue] || ''"
            />
          </td>
          <td>
            <v-btn
              elevation="0"
              fab
              x-small
              color="error"
              @click="openFormEditData(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="ml-2 mt-4">
      <v-col cols="4">
        <v-pagination
          v-model="params.offset"
          :length="getPageTotal(totalApp, params.limit)"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="params.limit"
          :items="limitOptions"
          label="Rows per page"
          dense
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // Vuex
  import { get, sync } from 'vuex-pathify'
  export default {
    name: 'Table',
    data () {
      return {
        isShowContent: false,
        metadata: [],
        dataKey: [],
      }
    },
    computed: {
      // From Store
      ...get('adminApp', [
        'listAllDataApp',
      ]),
      ...get('adminManager', [
        'dataApp',
        'totalApp',
      ]),
      ...get('layout', [
        'limitOptions',
      ]),
      ...sync('adminManager', [
        'params',
        'isShowDialogDataFormEdit',
        'dataForm',
        'dataKeyAttribute',
      ]),
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('adminManager/fetchDataListApp', this.getTokenLogin())
        },
        deep: true,
      },
      'params.limit': {
        handler (newVal, oldVal) {
          // when change limit then call api and offset start = 1
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminManager/fetchDataListApp', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    created () {
      const appId = this.$route.params.id
      const appChild = this.$route.params.keyAttribute
      const dataApp = this.listAllDataApp.find(c => c.id === appId)
      const metadata = dataApp?.items.find(c => c.keyAttribute === appChild)
      this.metadata = metadata?.metadata
      // get keyAttribute
      this.metadata.map((item) => {
        this.dataKey.push(item.keyAttribute)
      })
    },
    methods: {
      openFormEditData (data) {
        Object.keys(data).forEach(i => {
          data[i] = JSON.stringify(data[i], null, 6)
        })
        this.dataForm = data
        this.dataKeyAttribute = this.dataKey
        this.isShowDialogDataFormEdit = true
      },
    },
  }
</script>

<style scoped>
div /deep/ .v-list-group__header {
  padding: 0 !important;
}
</style>
