<template>
  <v-container
    id="admin-managers-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-account-outline"
    >
      <template #title>
        Quản lý app
      </template>
      <table-managers />
    </material-card>
    <dialog-data-form-edit />
  </v-container>
</template>

<script>
  import TableManagers from '../modules/admin/managers/components/Table'
  import DialogDataFormEdit from '../modules/admin/managers/components/DialogDataFormEdit'
  export default {
    name: 'AdminManagers',
    components: {
      TableManagers,
      DialogDataFormEdit,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin Managers',
    },
  }
</script>
