<template>
  <v-dialog
    v-model="isShowDialogDataFormEdit"
    fullscreen
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span
            class="primary--text font-weight-bold text-h5"
            v-text="$t('apps.appsForm.breadcrumb.titleAppUpdate')"
          />
          <v-spacer />
          <v-btn
            icon
            @click="isShowDialogDataFormEdit = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <template>
            <v-row>
              <v-col
                v-for="(keyAttribute, index) in dataKeyAttribute"
                :key="index"
                class="col-6"
              >
                <v-textarea
                  v-model="dataForm[keyAttribute]"
                  :label="keyAttribute"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </template>
         </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogDataFormEdit = false"
            v-text="$t('apps.appsForm.actions.cancel')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="clickUpdateApp"
            v-text="$t('apps.appsForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  // Vuex
  import { get, sync } from 'vuex-pathify'
  export default {
    name: 'DialogDataFormEdit',
    computed: {
      ...get('adminManager', [
        'dataKeyAttribute',
      ]),
      ...sync('adminManager', [
        'dataForm',
        'isShowDialogDataFormEdit',
      ]),
    },
  }
</script>

<style scoped>
</style>
